/* .socialWrapper {
  position: absolute;
  bottom: 9%;
  right: 10%;
}

.ant-list.ant-list-split.toolbar {
  height: unset !important;
}

.toolbar .ant-spin-container {
  display: inline-flex;
  justify-content: center;
  margin: 0;
  padding: 10px;
  list-style: none;
}

.toolbarItem {
  width: 40px;
  margin: 0 4px;
}

.toolbarLink {
  display: block;
}

.toolbarImg {
  display: block;
  width: 100%;
  height: auto;
} */




.wrapper {
  display: flex;
  justify-content: center;
}

.toolbar {
  display: inline-flex;
  justify-content: center;
  height: 40px !important;
  margin: 0;
  padding: 10px;
  background-color: #ffffff1c;
  list-style: none;
  border-radius: 15px;
  align-items: center;
}

.toolbarItem {
  width: 40px;
  margin: 0 4px;
}

.toolbarLink {
  display: block;
}

.toolbarImg {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 50%;
}