@font-face {
  font-family: ginger;
  src: url('./components/assets/fonts/ginger.woff');
}

.ginger h1,
.ginger h2,
.ginger h3,
.ginger h4,
.ginger h5,
.ginger p,
.ginger span,
.ginger button,
.ginger {
  font-family: 'ginger', serif;
}

body {
  cursor: none;
}

.muzieknootjes {
  display: block;
  margin: auto;
  position: relative;
  width: 50%;
  min-width: 300px;
  height: 200px;
  border: 0px solid #000;
}

.noot-1,
.noot-2,
.noot-3,
.noot-4 {
  position: absolute;
  animation: notes 2s infinite linear;
  font-size: 35px;
  opacity: 0;
  color: #fbcf35;
}

.noot-1 {
  bottom: 60px;
  left: 0;
  animation-delay: 0.5s;
}

.noot-2 {
  bottom: 30px;
  left: 30%;
  animation-delay: 1s;
}

.noot-3 {
  bottom: 90px;
  left: 60%;
  animation-delay: 1.5s;
}

.noot-4 {
  bottom: 40px;
  left: 90%;
  animation-delay: 2s;
}

@keyframes notes {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 0.3;
    transform: scale(1.5) translate(50%, -50%);
  }
  80% {
    opacity: 0;
    transform: scale(1.5) translate(100%, -100%);
  }
  100% {
    transform: scale(1.5) translate(100%, -100%);
    opacity: 0;
  }
}


.muzieknootjesMain {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.muzieknootjes {
    width: 100%;
    height: calc(100vh / 3);
}

.muzieknootjes div {
    opacity: 0.3;
}

.cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 50%;
  border: 2px solid #fad548;
  transition: 0.3s;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1000;
}

.cursor2 {
  position: fixed;
  width: 4px;
  height: 4px;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  transition: 0.1s;
  pointer-events: none;
  z-index: 1000;
}

.grow,
.grow-small {
  transform: scale(4);
  background: white;
  mix-blend-mode: difference;
  border: none;
}

.grow-small {
  transform: scale(2);
}

.HomeSectionRow {
  z-index: 1;
  position: relative;
}

.btnEnter {
  border: solid 2px #fff !important;
  border-radius: 0;
  padding: 30px 50px;
  line-height: 0;
  font-size: 22px;
  text-transform: uppercase;
}

.textMissing {
  font-size: 30px;
}

.textMissing {
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 30px !important;
  line-height: 30px !important;
}

.HomeSection {
  height: 100vh;
  background: url('./components/assets/images/hombg.png') no-repeat center center / cover;
  display: flex;
  align-items: flex-end;
  background-color: #000;
}

.castleWrapper>div {
  position: absolute;
  width: 50%;
  top: -10%;
  left: 25%;
  z-index: 1;
  height: auto;
}

.btnCenter {
  text-align: center;
}

.img-container::before {
  content: '';
  background: url('./components/assets/images/base.png') no-repeat;
  position: absolute;
  width: 100%;
  height: 28%;
  background-size: 100%;
  bottom: 5%;
  background-position: center center;
  z-index: -1;
}

.img-container:hover img {
  cursor: e-resize !important;
}

.btnCenter button {
  background: transparent;
  border: none;
  z-index: 1;
  color: #fff;
}

.menuSide ul li {
  position: relative;
}

.menuSide ul li:before,
.btnCenter button:before {
  background: #1b2961;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
  width: 0%;
  height: 100%;
}

.menuSide ul li:hover:before,
.btnCenter button:hover:before {
  width: 100%;
}

.btnCenter button:hover a {
  color: #fff;
}

.Home.pageWrapper {
  position: relative;
  overflow: hidden;
}

.carModule .img-container {
  border: 1px solid #ccc;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 0;
  cursor: ew-resize;
}

.carModule .img360 {
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 0;
}

.carModule img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.carModule #preload-imgs {
  display: none;
}

.scale-animation {
  transform: scale(4);
  transition: 5s ease;
  background-color: transparent !important;
  /* width: 100% !important;
  height: 100% !important; */
}

canvas {
  background-color: transparent !important;
}

canvas#canvas {
  position: absolute;
  background-color: transparent !important;
  width: 45%;
  height: 70vh;
  border-radius: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 5s ease;
}

.socialWrapper {
  text-align: right;
}

.HomeSectionRow div {
  color: #fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.btnCenter:before {
  content: '';
  background: url('./components/assets/images/arrow.png') no-repeat;
  background-size: contain;
  width: 30px;
  height: 72px;
  position: absolute;
  z-index: 1;
  margin: auto;
  bottom: 100%;
  left: 0;
  right: 0;
  animation: moveUpDown 1s infinite alternate;
}






/* @keyframes move {
  0% {
    left: 0;
  }

  50% {
    left: 85%;
  }

  100% {
    left: 0;
  }
} */

@keyframes moveUpDown {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
    /* Adjust the distance as needed */
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }

  .HomeSectionRow {
    padding-bottom: 30px;
  }
}

@media (min-width: 1601px) {
  .container {
    max-width: 1480px;
  }

  .HomeSectionRow {
    padding-bottom: 35px;
  }
}

/* START RESPONSIVE */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .castleWrapper>div {
    top: 7%;
  }

  canvas#canvas {
    height: 40vh;
    top: -20%;
  }

  .HomeSectionRow .ant-col:nth-child(3) {
    flex: 100%;
    max-width: 100%;
    margin-top: 35px;
  }

  .socialWrapper {
    text-align: center;
  }

  .HomeSectionRow {
    padding-bottom: 45px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  canvas#canvas {
    height: 44vh;
    top: -20%;
    width: 35%;
  }

  .img-container::before {
    left: 0;
    right: 0;
    margin: auto;
    width: 94%;
    height: 23%;
    bottom: 8%;
  }

  .HomeSectionRow .ant-col:nth-child(3) {
    flex: 100%;
    max-width: 100%;
    margin-top: 30px;
  }

  .socialWrapper {
    text-align: center;
  }

  .castleWrapper>div {
    top: 0;
  }

  .HomeSectionRow {
    padding-bottom: 45px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .container {
    max-width: 1260px !important;
  }

  .HomeSectionRow {
    padding-bottom: 50px;
  }

  canvas#canvas {
    height: 50vh;
    top: -16%;
    width: 40%;
  }
}